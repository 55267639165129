import React, {useEffect, useRef} from "react";
import { Col, Container, Row, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faSearch } from '@fortawesome/free-solid-svg-icons'

import slide1 from '../assets/img/slide-1.jpg'
import slide2 from '../assets/img/slide-2.jpg'
import icnAdesivos from '../assets/img/icn-adesivos.png'
import icnBackdrop from '../assets/img/icn-backdrop.png'
import icnBanner from '../assets/img/icn-banner.png'
import icnDisplay from '../assets/img/icn-display.png'
import icnMobile from '../assets/img/icn-mobile.png'
import icnStopper from '../assets/img/icn-stopper.png'
import icnWobbler from '../assets/img/icn-wobbler.png'
import icnClipStrip from '../assets/img/icn-clip-strip.png'

function HomePage(props) {

    const form = useRef();
  
    const htmlString = `
    <script type="text/javascript">
    var ss_form = {'account': 'MzawMDEzMzG3BAA', 'formID': 'M7VINTNNNDfWNTE3TdU1sUwx0E0yNDHTTTROM7EwS0ozSE0xAgA'};
    ss_form.width = '100%';
    ss_form.domain = 'app-3QNDRBO0M2.marketingautomation.services';
    </script>
    <script type="text/javascript" src="https://koi-3QNDRBO0M2.marketingautomation.services/client/form.js?ver=2.0.1"></script>
    `;
  
    useEffect(() => {
      const fragment = document.createRange().createContextualFragment(htmlString);
      form.current.append(fragment);
    }, [htmlString, form]);
  

    return (
        <>
            <div className="container">
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-lg-3 text-sm-center">
                        <img src={require('../assets/img/Logo.png')} className="img-fluid mx-auto d-block" alt="" />
                    </div>
                    <div className="col-lg-6 py-3 ly-lg-0">
                        <div className="form-container">
                            <form>
                                <input className="form-control" type="search" placeholder="Busque por um produto..." />
                                <button type="submit"><FontAwesomeIcon icon={faSearch} /></button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-3 d-flex align-self-center text-sm-center">
                        <a className="btn btn-verde mx-auto d-block" href="#orcamento"><FontAwesomeIcon icon={faCartShopping} /> Orçamento</a>
                    </div>
                </div>
            </div>
            <nav className="navbar justify-content-center navbar-expand-lg">
                <div className="container">
                    <button className="navbar-toggler mx-auto d-block d-lg-none" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav justify-content-center  w-100 mb-2 mb-lg-0">
                            <li className="nav-item px-2">
                                <a className="nav-link" aria-current="page" href="#">Início</a>
                            </li>
                            <li className="nav-item  px-2">
                                <a className="nav-link" href="#quem-somos">Quem Somos</a>
                            </li>
                            <li className="nav-item  px-2">
                                <a className="nav-link" href="#produtos">Produtos</a>
                            </li>
                            <li className="nav-item  px-2">
                                <a className="nav-link" href="#materiais">Materiais Utilizados</a>
                            </li>
                            <li className="nav-item  px-2">
                                <a className="nav-link" href="#processos">Processos Produtivos</a>
                            </li>
                            <li className="nav-item  px-2">
                                <a className="nav-link" href="#orcamento">Contato</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>

            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="d-block w-100" src={slide1} alt="" />
                        <div className="carousel-caption col-lg-5">
                            <h3>Impressão de alta qualidade para materiais promocionais</h3> 
                            <a className="btn btn-borda mt-3" href="#produtos">Confira nossos produtos</a>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100" src={slide2} alt="" />
                        <div className="carousel-caption col-lg-5">
                            <h3>Impressão de alta qualidade para materiais promocionais</h3>
                            <a className="btn btn-borda mt-3"  href="#produtos">Confira nossos produtos</a>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            <div className="container-fluid" id="produtos">
                <div className='row text-center  d-flex align-items-center py-4'>
                    <div className="col p-lg-5">
                        <a href="https://pages.services/adesiva.com.br/adesiva-entregaveis-adesivo-de-chao/">
                        <img src={icnAdesivos} className="img-fluid" alt="" /> <br />
                        <h4>Adesivos</h4>
                        </a>
                    </div>
                    <div className="col p-lg-5">
                        <a href="http://pages.services/adesiva.com.br/adesiva-entregaveis-backdrop">
                        <img src={icnBackdrop} className="img-fluid" alt="" /> <br />
                        <h4>Backdrop</h4>
                        </a>
                    </div>
                    <div className="col p-lg-5">
                    <a href="http://pages.services/adesiva.com.br/adesiva-entregaveis-banner-promocional">
                        <img src={icnBanner} className="img-fluid" alt="" /> <br />
                        <h4>Banner</h4>
                        </a>
                    </div>
                    <div className="col p-lg-5">
                    <a href="https://pages.services/adesiva.com.br/adesiva-entregaveis-displays-de-cho">
                        <img src={icnDisplay} className="img-fluid" alt="" /> <br />
                        <h4>Displays</h4>
                        </a>
                    </div>
                    <div className="col p-lg-5">
                    <a href="https://pages.services/adesiva.com.br/adesiva-entregaveis-mobile">
                        <img src={icnMobile} className="img-fluid" alt="" /> <br />
                        <h4>Móbile</h4>
                        </a>
                    </div>
                    <div className="col p-lg-5">
                    <a href="https://pages.services/adesiva.com.br/adesiva-entregaveis-clip-strip">
                        <img src={icnClipStrip} className="img-fluid" alt="" /> <br />
                        <h4>Clip Strip</h4>
                        </a>
                    </div>
                    <div className="col p-lg-5">
                    <a href="https://pages.services/adesiva.com.br/adesiva-entregaveis-stopper">
                        <img src={icnStopper} className="img-fluid" alt="" /> <br />
                        <h4>Stopper</h4>
                        </a>
                    </div>
                    <div className="col p-lg-5 ">
                    <a href="http://pages.services/adesiva.com.br/adesiva-entregaveis-wobbler">
                        <img src={icnWobbler} className="img-fluid" alt="" /> <br />
                        <h4>Wobbler</h4>
                        </a>
                    </div>
                </div>
            </div>

            <div className="container" id="quem-somos">
                <div className="row pb-3 pb-lg-5">
                    <Col lg={4}>
                        <Card className="mb-3 mb-lg-0">
                            <Card.Img variant="top" src={require('../assets/img/img-atividade.png')} className="img-fluid w-100" alt="" />
                            <Card.Body  className="bg-verde text-light text-center">
                                <Card.Text>
                                    +200 mil <br />
                                    horas de atividade
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card className="mb-3 mb-lg-0">
                            <Card.Img variant="top" src={require('../assets/img/img-impressoes.png')} className="img-fluid w-100" alt="" />
                            <Card.Body  className="bg-verde text-light text-center">
                                <Card.Text>
                                    +1 bilhão<br />
                                    de impressões
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card className="mb-3 mb-lg-0">
                            <Card.Img variant="top" src={require('../assets/img/img-marcas.png')} className="img-fluid w-100" alt="" />
                            <Card.Body  className="bg-verde text-light text-center">
                                <Card.Text>
                                    +5 mil <br />
                                    marcas e empresas
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </div>
                <div className="text-center ">
                <a className="btn btn-verde mb-5" href="#orcamento">Solicitar Orçamento</a>
                </div>
            </div>
            <div className="bg-verde-escuro text-light">
                <div className="container">
                <div className="row text-center py-5">
                        <div className="col-lg-8 offset-lg-2">
                            A ADESIVA, como carinhosamente chamamos, é uma empresa que se desenvolveu ao longo dos anos, além do material plástico flexível com cola (adesivos), expandiu a sua capacidade de impressão em chapas de Polipropileno (PP), PVC (Policloreto de Vinila), Poliestireno (PS) e o PET (Poliéster). Hoje, a ADESIVA figura como uma das empresas mais experientes na impressão offset de materiais plásticos com o uso da Tecnologia UV. Aliás essa mentalidade também nos fez investir em tecnologia digital para impressão de chapas em grandes formatos, e associado ao sistema digital de corte, produzir displays e materiais para ponto de venda com diferentes complexidades.
                        </div>
                    </div>
                </div>
            </div>

            <div className="container" id="materiais">
                <div className="row text-center g-3 py-lg-5">
                    <h1 className="mt-5 mb-3"><span className='verde'>Materiais</span> <span className='verde-escuro'>Utilizados</span></h1>
                    <div className="col">
                        <a href="http://pages.services/adesiva.com.br/adesiva-materiais-chapas-de-pp" className="box h-100">
                            <img src={require('../assets/img/icn-chapa-pp.png')} alt="" /> <br />
                            <p>Chapas de PP (Polipropileno)</p>
                        </a>
                    </div>
                    <div className="col">
                        <a href="http://pages.services/adesiva.com.br/adesiva-entregaveis-chapas-de-ps" className="box box h-100">
                            <img src={require('../assets/img/icn-chapa-ps.png')} alt="" /> <br />
                            <p>Chapas de PS (Poliestireno)</p>
                        </a>
                    </div>
                    <div className="col">
                        <a href="http://pages.services/adesiva.com.br/adesiva-entregaveis-chapas-de-petg" className="box box h-100">
                            <img src={require('../assets/img/icn-chapa-petg.png')} alt="" /> <br />
                            <p>Chapas de PETg</p>
                        </a>
                    </div>
                    <div className="col">
                        <a href="https://pages.services/adesiva.com.br/adesiva-entregaveis-peliculas" className="box box h-100">
                            <img src={require('../assets/img/icn-peliculas.png')} alt="" /> <br />
                            <p>Películas de Poliéster</p>
                        </a>
                    </div>
                    <div className="col">
                        <a href="http://pages.services/adesiva.com.br/adesiva-entregaveis-adesivos" className="box box h-100">
                            <img src={require('../assets/img/icn-adesivos-pvc.png')} alt="" /> <br />
                            <p>Adesivos de PVC</p>
                        </a>
                    </div>
                    <div className="col">
                        <a href="https://pages.services/adesiva.com.br/adesiva-entregaveis-vinil-eletrostatico" className="box box h-100">
                            <img src={require('../assets/img/icn-vinil.png')} alt="" /> <br />
                            <p>Vinil Eletrostático</p>
                        </a>
                    </div>
                    <div className="col">
                        <a href="http://pages.services/adesiva.com.br/adesiva-entregaveis-papel-duplex" className="box box h-100">
                            <img src={require('../assets/img/icn-papel.png')} alt="" /> <br />
                            <p>Papel Duplex</p>
                        </a>
                    </div>
                    <div className="col">
                        <a href="http://pages.services/adesiva.com.br/adesiva-entregaveis-lona-de-pvc" className="box box h-100 ">
                            <img src={require('../assets/img/icn-lona.png')} alt="" /> <br />
                            <p>Lona de PVC</p>
                        </a>
                    </div>
                </div>
            </div>

            <div className="container" id="processos">
                <div className="row text-center g-3 pb-lg-5">
                <h1 className="mt-5 mb-3"><span className='verde'>Processos</span> <span className='verde-escuro'>Produtivos</span></h1>
                    <div className="col-lg-2 col-xs-6 h-100">
                        <a href="https://pages.services/adesiva.com.br/processosprodutivos-impressaooffset" className="box h-100">
                            <img src={require('../assets/img/processos-impressao-uv.png')} alt="" /> <br />
                            <p>Impressão Offset UV</p>
                        </a>
                    </div>
                    <div className="col-lg-2 col-xs-6 h-100">
                        <a href="https://pages.services/adesiva.com.br/adesiva-processos-impresso-digital-uv" className="box h-100">
                            <img src={require('../assets/img/processos-impressao-digital.png')} alt="" /> <br />
                            <p>Impressão Digital UV</p>
                        </a>
                    </div>
                    <div className="col-lg-2 col-xs-6 h-100">
                        <a href="https://pages.services/adesiva.com.br/adesiva-processos-impresso-latex" className="box h-100">
                            <img src={require('../assets/img/processos-impressao-latex.png')} alt="" /> <br />
                            <p>Impressão Látex</p>
                        </a>
                    </div>
                    <div className="col-lg-2 col-xs-6 h-100">
                        <a href="http://pages.services/adesiva.com.br/adesiva-processos-corte-digital-esko" className="box h-100">
                            <img src={require('../assets/img/processos-esko.png')} alt="" /> <br />
                            <p>Esko</p>
                        </a>
                    </div>
                    <div className="col-lg-2 col-xs-6 h-100">
                        <a href="https://pages.services/adesiva.com.br/adesiva-processos-impresso-3d" className="box h-100">
                            <img src={require('../assets/img/processos-impressora-3d.png')} alt="" /> <br />
                            <p>Impressora 3D</p>
                        </a>
                    </div>
                    <div className="col-lg-2 col-xs-6 h-100">
                        <a href="http://pages.services/adesiva.com.br/adesiva-processos-impresso-uv-gel" className="box 100">
                            <img src={require('../assets/img/processos-impressao-uvgel.png')} alt="" /> <br />
                            <p>Impressão UV Gel</p>
                        </a>
                    </div>
                </div>
            </div>

            <div id="orcamento" className="bg-verde-escuro text-light py-5 mt-5">
                <Container>
                    <Row className="text-center">
                        <Col className="col-lg-6 offset-lg-3">
                            <FontAwesomeIcon icon={faCartShopping} size="2x"/>
                            <h1>Solicite um Orçamento</h1> <br />
                            <p>(11) 3347-3888 - adesiva@adesiva.com.br</p>
                            <p>Tire suas dúvidas ou faça o seu orçamento preenchendo o formulário:</p>
                            <div ref={form} />
                            
                            
                             {/* <iframe src="./form.html" width="100%" height="600"/> */}
                        </Col>
                    </Row>
                </Container>
            </div>

            <footer className="py-5 bg-verde text-light">
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            &copy; 2022 Adesiva Gráfica. Todos os direitos reservados.
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default HomePage;